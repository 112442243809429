import { Box } from "@mui/material";
import HorizontalScrollContainer from "component/HorizontalScrollContainer";
import SectionTitle from "component/SectionTitle";
import OpenCampusCard2 from "component/HomeContent/OpenCampusCard2";
import OpenCampusImg1 from "assets/images/open-campus/1.png";

function OpenCampusSection() {
  return (
    <Box py={6} position="relative" id="open-campus">
      <SectionTitle
        title="OPEN CAMPUS"
        subTitle="オープンキャンパス"
        py={6}
        textAlign={["center", "center", "center", "left"]}
      />
      {/* <Box
        display="flex"
        justifyContent={["space-evenly", "space-evenly", "normal"]}
        gap={3}
        width="100%"
      >
        <OpenCampusCard2
          imgSrc={OpenCampusImg1}
          date="7月16日(Sun)"
          href="https://www.tsu.ac.jp/event/opencampus/20230531-2/"
          alt=""
        />
        <OpenCampusCard2
          imgSrc={OpenCampusImg1}
          date="8月6日(Sun)"
          href="https://www.tsu.ac.jp/event/opencampus/20230626/"
          alt=""
        />
      </Box> */}
      <HorizontalScrollContainer buttonProps={{ top: "40%" }}>
        <OpenCampusCard2
          imgSrc={OpenCampusImg1}
          date="レッスン詳細"
          href="https://www.tsu.ac.jp/event/opencampus/20240409/"
          alt=""
          eventType="授業見学会&ピアノ体験レッスン"
        />
        <OpenCampusCard2
          imgSrc={OpenCampusImg1}
          date="2025年度"
          href="https://www.tsu.ac.jp/event/opencampus/"
          alt=""
        />
      </HorizontalScrollContainer>
      {/* <HorizontalScrollContainer>
        <OpenCampusCard1 />
        <OpenCampusCard2 imgSrc={OpenCampusImg1} date="3月25日(sat)" alt="" />
        <OpenCampusCard2 imgSrc={OpenCampusImg2} date="3月12日(sun)" alt="" />
        <OpenCampusCard2 imgSrc={OpenCampusImg3} date="3月18日(sat)" alt="" />
        <OpenCampusCard2 imgSrc={OpenCampusImg4} date="3月19日(sun)" alt="" />
      </HorizontalScrollContainer> */}
    </Box>
  );
}

export default OpenCampusSection;
